
<nb-layout >
    <nb-layout-column style="background-color: #dde1e7;">
    <ng-container style="display: flex;justify-content: center; align-items: center;">
                        <nb-auth-block style="display: unset !important;">
                            <router-outlet></router-outlet>
                        </nb-auth-block>
                    </ng-container>
    </nb-layout-column>
</nb-layout>

