import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { delay, map } from 'rxjs/operators';

// const TOTAL_PAGES = 7;

// export class NewsPost {
//   title: string;
//   link: string;
//   creator: string;
//   text: string;
// }

@Injectable()
export class LibraryService {

  data1: any;
  // data1: any;
 
  constructor(private http: HttpClient) {

  }
  private sharedData: any;
  setData(data: any) {
    // console.log(data);
    this.sharedData = data;
  }

  getData() {
    // console.log(this.sharedData);
    
    return this.sharedData;
  }
}


