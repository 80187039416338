import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NbToastrService } from '@nebular/theme';

@Component({
  selector: 'ngx-changepassword',
  templateUrl: './changepassword.component.html',
  styleUrls: ['./changepassword.component.scss']
})
export class ChangepasswordComponent implements OnInit {

  showOldPassword: boolean = false;
  showNewPassword: boolean = false;
  showConfirmPassword: boolean = false;
  oldPasswordValidation: boolean = false;
  newPasswordValidation: boolean = false;
  confirmPasswordValidation: boolean = false;
  oldPassword: string = "";
  newPassword: string = "";
  confirmPassword: string = "";
  submitted: boolean = false;

  constructor(private router: Router,private toastrService: NbToastrService) { }

  ngOnInit(): void {
  }

  validateUser() {
    this.submitted = true;
  
    this.oldPasswordValidation = this.oldPassword === '';
    this.newPasswordValidation = this.newPassword === '';
    this.confirmPasswordValidation = this.confirmPassword === '';
  
    if (this.isValidUser()) {
      this.router.navigate(['/auth/login']);
      this.showToast('', 'Password Change Successfully', 'success')
    }
    else {
      this.showToast('Please fill all mandatory fields ', 'Incomplete Form', 'warning')
    }
  }
  
  private isValidUser(): boolean {
    return !this.oldPasswordValidation && !this.newPasswordValidation && !this.confirmPasswordValidation;
  }
  
  togglePasswordVisibility(type: string): void {
    switch (type) {
      case 'oldPassword':
        this.showOldPassword = !this.showOldPassword;
        break;
      case 'newPassword':
        this.showNewPassword = !this.showNewPassword;
        break;
      case 'confirmPassword':
        this.showConfirmPassword = !this.showConfirmPassword;
        break;
    }
  }

  showToast(msg: any, title: any, status: any) {
    this.toastrService.show(msg, title, { status });
  }


}
