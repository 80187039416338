<div class="container" style="
    height: 70vh;
    width: 75vw;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);">

  <div class="contact-box">
    <div class="left"></div>
    <div class="right" action="" onsubmit="">
      <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css">
      <div class="content">
        <img src="https://media.licdn.com/dms/image/C4D0BAQG_FmDfiQjHPA/company-logo_200_200/0/1654695778898?e=2147483647&v=beta&t=3YoZIlZ5sIXxs-M2T0wwP7glK5Isnxh6xzLJQHg-97A">
        <div class="text">
          <h2 style="color: blueviolet;">DOOH</h2>
        </div>
        <form action="#">
          <div class="form-scope">
            <label class="label">Email Id</label>
            <div class="field">
              <span class="fa fa-user"></span>
              <input type="text" placeholder="Email Id" [(ngModel)]="email" required [ngModelOptions]="{standalone: true}">
            </div>
            <div class="validation-msg" *ngIf="submitted && emailValidation">Email ID is required</div>
          </div>

          <div class="form-scope">
            <label class="label">Password</label>
            <div class="field">
              <span class="fa fa-lock"></span>
              <input type="{{ showPassword ? 'text' : 'password' }}" placeholder="Password" [(ngModel)]="password" [ngModelOptions]="{standalone: true}">
              <span (click)="togglePasswordVisibility()" [ngClass]="showPassword ? 'fa fa-eye' : 'fa fa-eye-slash'"></span>
            </div>
            <div class="validation-msg" *ngIf="submitted && passwordValidation">Password is required</div>
          </div>

          <button (click)="validateUser();">Log in</button>

          <div class="or">Or</div>
          <div class="google-signin">
            <i class="fa fa-google"></i>
            <!-- <img src="../../../src/assets/images/google-icon.png" alt="" srcset=""> -->
            <a href="#">Sign In With Google</a>
          </div>

          <div class="icon-button">
            <span nbButton routerLink="/auth/reset-password">Change Password</span>
            <span nbButton routerLink="/auth/sign-up">Sign-In</span>
          </div>
        </form>
      </div>
    </div>


  </div>
</div>