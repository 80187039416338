<div class="container" style="
    height: 70vh;
    width: 75vw;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);">

  <div class="contact-box">
    <div class="left"></div>
    <div class="right" action="" onsubmit="">
      <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css">
      <div class="content">
        <img src="https://media.licdn.com/dms/image/C4D0BAQG_FmDfiQjHPA/company-logo_200_200/0/1654695778898?e=2147483647&v=beta&t=3YoZIlZ5sIXxs-M2T0wwP7glK5Isnxh6xzLJQHg-97A">
        <div class="text">
          <h2 style="color: blueviolet;">DOOH</h2>
        </div>
        <form action="#">

          <div class="form-scope">
            <div class="row">
                <div class="col-lg-6">
                    <label class="label">First Name<span style="color: red;">*</span></label>
                    <div class="field">
                    <span class="fa fa-user"></span>
                    <input type="text" placeholder="First Name" class="fname" [(ngModel)]="fname" required [ngModelOptions]="{standalone: true}">
                    </div>
                    <div class="validation-msg" *ngIf="submitted && fnameValidation">First Name is required</div>
                </div>
                <div class="col-lg-6">
                    <label class="label">Last Name<span style="color: red;">*</span></label>
                    <div class="field">
                    <span class="fa fa-user"></span>
                    <input type="text" placeholder="Last Name" class="lname" [(ngModel)]="lname" required [ngModelOptions]="{standalone: true}">
                    </div>
                    <div class="validation-msg" *ngIf="submitted && lnameValidation">Last Name is required</div>
                </div>
            </div>
          </div>

          <div class="form-scope">
            <label class="label">Email Id<span style="color: red;">*</span></label>
            <div class="field">
              <span class="fa fa-user"></span>
              <input type="text" placeholder="Email Id" [(ngModel)]="email" required [ngModelOptions]="{standalone: true}">
            </div>
            <div class="validation-msg" *ngIf="submitted && emailValidation">Email ID is required</div>
          </div>

          <div class="form-scope">
            <label class="label">Phone No.<span style="color: red;">*</span></label>
            <div class="field">
              <span class="fa fa-phone"></span>
              <input type="number" min="0" placeholder="Phone No." [(ngModel)]="phone" required [ngModelOptions]="{standalone: true}">
            </div>
            <div class="validation-msg" *ngIf="submitted && phoneValidation">Phone is required</div>
          </div>

          <div class="form-scope">
            <label class="label">Password<span style="color: red;">*</span></label>
            <div class="field">
              <span class="fa fa-lock"></span>
              <input type="{{ showPassword ? 'text' : 'password' }}" placeholder="Password" [(ngModel)]="password" [ngModelOptions]="{standalone: true}">
              <span (click)="togglePasswordVisibility()" [ngClass]="showPassword ? 'fa fa-eye' : 'fa fa-eye-slash'"></span>
            </div>
            <div class="validation-msg" *ngIf="submitted && passwordValidation">Password is required</div>
          </div>

          <button (click)="registerUser();">Sign Up</button>

        </form>
      </div>
    </div>


  </div>
</div>