<div class="container" style="
    height: 70vh;
    width: 75vw;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);">

  <div class="contact-box">
    <div class="left"></div>
    <div class="right" action="" onsubmit="">
      <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css">
      <div class="content">
        <img src="https://media.licdn.com/dms/image/C4D0BAQG_FmDfiQjHPA/company-logo_200_200/0/1654695778898?e=2147483647&v=beta&t=3YoZIlZ5sIXxs-M2T0wwP7glK5Isnxh6xzLJQHg-97A">
        <div class="text">
          <h2 style="color: blueviolet;">DOOH</h2>
        </div>
        <form action="#">

          <div class="form-scope">
            <label class="label">Old Password</label>
            <div class="field">
              <span class="fa fa-lock"></span>
              <input type="{{ showOldPassword ? 'text' : 'password' }}" placeholder="Old Password" [(ngModel)]="oldPassword" [ngModelOptions]="{standalone: true}">
              <span (click)="togglePasswordVisibility('oldPassword')" [ngClass]="showOldPassword ? 'fa fa-eye' : 'fa fa-eye-slash'"></span>
            </div>
            <div class="validation-msg" *ngIf="submitted && oldPasswordValidation">Old Password is required</div>
          </div>


          <div class="form-scope">
            <label class="label">New Password</label>
            <div class="field">
              <span class="fa fa-lock"></span>
              <input type="{{ showNewPassword ? 'text' : 'password' }}" placeholder="New Password" [(ngModel)]="newPassword" [ngModelOptions]="{standalone: true}">
              <span (click)="togglePasswordVisibility('newPassword')" [ngClass]="showNewPassword ? 'fa fa-eye' : 'fa fa-eye-slash'"></span>
            </div>
            <div class="validation-msg" *ngIf="submitted && newPasswordValidation">New Password is required</div>
          </div>

          <div class="form-scope">
            <label class="label">Confirm Password</label>
            <div class="field">
              <span class="fa fa-lock"></span>
              <input type="{{ showConfirmPassword ? 'text' : 'password' }}" placeholder="Confirm Password" [(ngModel)]="confirmPassword" [ngModelOptions]="{standalone: true}">
              <span (click)="togglePasswordVisibility('confirmPassword')" [ngClass]="showConfirmPassword ? 'fa fa-eye' : 'fa fa-eye-slash'"></span>
            </div>
            <div class="validation-msg" *ngIf="submitted && confirmPasswordValidation">Confirm Password is required</div>
          </div>

          <button (click)="validateUser();">Change Password</button>

        </form>
      </div>
    </div>


  </div>
</div>