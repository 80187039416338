

import { state } from '@angular/animations';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NbToastrService } from '@nebular/theme';

@Component({
  selector: 'ngx-signin',
  templateUrl: './signin.component.html',
  styleUrls: ['./signin.component.scss', 
]
})
export class SigninComponent implements OnInit {

  email: string = '';
  password: string = '';
  showPassword: boolean = false;
  submitted: boolean = false;
  emailValidation: boolean = false;
  passwordValidation: boolean = false;
  socialAuthService: any;

  constructor(private router: Router,private toastrService: NbToastrService) { }

  ngOnInit(): void {
  }

  validateUser() {
    this.submitted = true;
  
    this.emailValidation = this.email === '';
    this.passwordValidation = this.password === '';
  
    if (this.isValidUser()) {
      localStorage.setItem('role', this.email);
      this.router.navigate(['/pages/dashboard']);
      this.showToast('Login Successful', 'Login Successful', 'success');
      // this.showToast.fire();
    }
    else {
      this.showToast('Please check the Email id or password combination', 'Invalid Inputs', 'warning')
    }
  }
  
  private isValidUser(): boolean {
    const isAdmin = this.email === 'admin' && this.password === 'admin@123';
    const isAdvertiser = this.email === 'advertiser' && this.password === 'advertiser@123';
    
    return !this.emailValidation && !this.passwordValidation && (isAdmin || isAdvertiser);
  }
  
  
  togglePasswordVisibility(): void {
    this.showPassword = !this.showPassword;
  }

  showToast(msg: any, title: any, status: any) {
    this.toastrService.show(msg, title, { status });
  }

}
