import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NbToastrService } from '@nebular/theme';

@Component({
  selector: 'ngx-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.scss']
})
export class SignupComponent implements OnInit {

  fname: string = '';
  lname: string = '';
  email: string = '';
  phone: number;
  password: string = '';
  showPassword: boolean = false;
  submitted: boolean = false;
  fnameValidation: boolean = false;
  lnameValidation: boolean = false;
  emailValidation: boolean = false;
  phoneValidation: boolean = false;
  passwordValidation: boolean = false;
  socialAuthService: any;

  constructor(private router: Router,private toastrService: NbToastrService) { }

  ngOnInit(): void {
  }

  registerUser() {
    this.submitted = true;
  
    this.fnameValidation = this.fname === '';
    this.lnameValidation = this.lname === '';
    this.emailValidation = this.email === '';
    this.phoneValidation = this.phone === null || this.phone === undefined;
    this.passwordValidation = this.password === '';
  
    if (this.isValidUser()) {
      this.router.navigate(['/auth/login']);
      this.showToast('', 'User Created Successfully', 'success')
    }
    else {
      this.showToast('Please fill all mandatory fields ', 'Incomplete Form', 'warning')
    }
  }
  
  private isValidUser(): boolean {
    return !this.fnameValidation && !this.lnameValidation && !this.emailValidation && !this.phoneValidation && !this.passwordValidation;
    // return !(this.fnameValidation || this.lnameValidation || this.emailValidation || this.phoneValidation || this.passwordValidation);
  }
  
  togglePasswordVisibility(): void {
    this.showPassword = !this.showPassword;
  }

  showToast(msg: any, title: any, status: any) {
    this.toastrService.show(msg, title, { status });
  }
}
